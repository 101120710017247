import React from "react";
import "./css/style.css";

import PageIllustration from "./components/PageIllustration";
import HeroHome from "./components/HeroHome";
import Workflows from "./components/Workflows";
import Features from "./components/Features";
import StatsCarousel from "./components/StatsCarousel";
import ContactUs from "./components/ContactUs";

export default function Home() {
  return (
    <>
      <PageIllustration multiple={false} />
      <div id="home"><HeroHome /></div>
      <div id="aboutus"><Features /></div>
      <div id="products"><Workflows /></div>
      
      <div id="stats">
        <PageIllustration multiple={true} /> 
        <StatsCarousel />
      </div>
      
      <div id="contactus"><ContactUs /></div>
    </>
  );
}
